import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './StartingInfo.scss';
import {animated,useSpring,useSprings} from "@react-spring/web";
import { useRef} from "react";

function TitleSection()
{
    const inter = useRef(false);

    const titleAnimation = useSpring({
        from: {x:"-200%",size:"10px"},
        to: [{x:"0%"}],
        config: {tension:115},
    });

    const rotateAnchorInformation = 
    {

        from: {opacity: 0, rotate: "100deg"},
        to: {opacity: 1, rotate: "0deg"},
        config: {tension: 400,friction: 45,mass: 7},
        delay: 600,
        onRest: () =>
        {
            inter.current = true;
        }
    }
    const [rotateAnchors,anchorAPI] = useSprings(2,(() => (rotateAnchorInformation))
    );

    const anchorHover = (place) => {
        
        if(!inter.current)
            return;

        const fn = (pos) => index => 
        pos === index ? 
        {
            loop: true,
            from: {rotate: "0deg"},
            to: [{rotate: "2deg"},{rotate:"-2deg"}],
            config: {mass: .01, tension: 800,friction:10}
        }
        : 
        null;
        anchorAPI.start(fn(place));
    }

    const anchorHoverStop = (event) => {
        if(!inter.current)
            return;
        
        anchorAPI.stop();
        anchorAPI.start(
        {
            rotate: "0deg"
        });
    }

    const rotateMainImage = useSpring({
        from: {opacity: 0, height:"0px"},
        to: {opacity: 1,height:"400px"},
        delay: 500
    });


return(
    <animated.div className="titleSection" style={{...titleAnimation}}>
        <h1 style={{fontSize:"80px"}}>Andrew Eason</h1>
            <animated.img src="../AndrewEasonImage.svg" style={{width:"25vw",height:"25vh",marginBottom:"20px",...rotateMainImage}} alt="Andrew Eason" />
        <h2 style={{fontSize:"50px"}}>Programmer</h2>


        <div className="titleIcons">
            <animated.a style={{...rotateAnchors[0]}} name="1" className="titleIconElement" href="https://github.com/andeason" onMouseOver={() => {anchorHover(0)}} onMouseOut={anchorHoverStop}>
                <img style={{minWidth:"1%", minHeight:"1%",maxWidth:"50px",maxHeight:"50px"}} src="../github-mark.svg" alt="Github link" />
            </animated.a>

            <animated.a style={{...rotateAnchors[1]}} name="2" className="titleIconElement" href="mailto:aeason921@gmail.com" onMouseOver={() => anchorHover(1)} onMouseOut={anchorHoverStop}>
                <img style={{minWidth:"1%", minHeight:"1%",maxWidth:"50px",maxHeight:"50px"}}src="../email-icon.svg" alt="Email link" />
            </animated.a>

        </div>
    </animated.div>

);
};

export default TitleSection;