import './App.scss';
import StartingInfo from './StartingInfo.jsx';
import TitleSection from './TitleSection';
import React, {useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {animated,useSpring } from '@react-spring/web';


function App() 
{

  const userSection = useRef(null);

  const buttonSpring = useSpring(
    {
      from:{opacity:0},
      to:{opacity:1},
      delay:1700
    }
    );

  
  return (
    <div className="App">
      <TitleSection />
      <animated.button style={{marginBottom:"20vh",...buttonSpring}} className="positionArrow" onClick={() => {userSection.current.scrollIntoView()}}>
        <animated.img src="../down-arrow.svg" style={{width:"30px", height:"30px"}}/>
      </animated.button>

      <animated.button ref={userSection} style={{transform:"scale(1,-1)",marginBottom:"10vh",...buttonSpring}} className="positionArrow" onClick={() => {document.body.scrollIntoView()}}>
        <animated.img src="../down-arrow.svg" style={{width:"30px", height:"30px"}}/>
      </animated.button>
      <StartingInfo />
    </div>
  );
}


export default App;