import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './StartingInfo.scss';
import { animated,useSpring } from '@react-spring/web';
import {useRef,useEffect} from "react";
function StartingInfo()
{
    //Note that for the useRefs, since we transition out of the page, we need these to find positions
    //The downside is that it forces only at one.  Thresholds dont ever occur.
    const firstBox = useRef(null);
    const [firstBoxSpring, firstAPI] = useSpring(() => (
    {
        opacity: 0,
        x:"-200%",
    }));

    const secondBox = useRef(null);
    const [secondBoxSpring,secondAPI] = useSpring(() => ({
        opacity:0,
        x:"-200%",
    }));

    const thirdBox = useRef(null);
    const [thirdBoxSpring,thirdAPI] = useSpring(() => ({
        opacity:0,
        x:"200%",
    }))

    useEffect(() => 
    {
        const firstBoxEntry = (entry) =>
        {
            if(entry[0].intersectionRatio === 1)
            {
            firstAPI.start(
                {opacity:1, x:"0%"}
            );
            }
            else
            firstAPI.start(
                {opacity:0, x:"-200%"}
            );
        }

        const secondBoxEntry = (entry) =>
        {
            if(entry[0].intersectionRatio === 1)
            {
            secondAPI.start(
                {opacity:1, x:"0%",delay:100}
            );
            }
            else
            secondAPI.start(
                {opacity:0, x:"-200%"}
            );
        }

        const thirdBoxEntry = (entry) =>
        {
            if(entry[0].intersectionRatio === 1)
            {
            thirdAPI.start(
                {opacity:1, x:"0%",delay:200}
            );
            }
            else
            thirdAPI.start(
                {opacity:0, x:"200%"}
            );
        }

        if(firstBox.current)
        {
            const observer = new IntersectionObserver(firstBoxEntry,{root:null,rootMargin:"0px",threshold:[0,1]});
            observer.observe(firstBox.current);
        }
        if(secondBox.current)
        {
            const observer2 = new IntersectionObserver(secondBoxEntry,{root:null,rootMargin:"0px",threshold:[0,1]});
            observer2.observe(secondBox.current);
        }
        if(thirdBox.current)
        {
            const observer3 = new IntersectionObserver(thirdBoxEntry,{root:null,rootMargin:"0px",threshold:[0,1]});
            observer3.observe(thirdBox.current);
        }
    },[firstAPI,secondAPI,thirdAPI]);

    return(
        <div className = "infoPanel">

            <animated.div style={{marginLeft:"15%",marginRight:"15%",...firstBoxSpring}} className="contentHolder">
                <p>
                    Hello!  This is a basic website for my own purposes for testing.  I mainly code these things for fun and for learning, if possible.  
                    I have used a variety of languages for programming that I use depending on what I need.  I mainly have worked in languages like C#, Python, Java, and Javascript.
                </p>
            </animated.div>
            <div ref={firstBox} />

            <animated.div style={{marginLeft:"15%",marginRight:"15%",...secondBoxSpring}} className="contentHolder">
                <p style={{paddingBottom: "25px"}}>
                    I also have experience with languages such as PHP, Lua, C, and C++ along with various database languages and other systems
                    There are countless other things I wish to learn along with some languages that I have worked with that I may have forgotten.
                </p>
            </animated.div>
            <div ref={secondBox} />

            <animated.div style={{marginLeft:"15%",marginRight:"15%",...thirdBoxSpring}} className="contentHolder">
                <p>
                    In the future, I might add much more to this website (such as a blog of some sort).
                    But, for now, it is a basic info section about me and various links if you are interested in contacting me. Thank you for visiting! 
                </p>
            </animated.div>
            <div ref={thirdBox}/>
        
        </div>
    );
    
}


export default StartingInfo;